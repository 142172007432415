.flyingObjectRight {
  position: absolute;
  width: 285px;
  top: 150px;
  right: -150px;
  z-index: 5;
  display: none;

  @media screen and (min-width: 1200px) {
    display: block;
  }

  @media screen and (min-width: 1400px) {
    right: -120px;
  }
}

.flyingObjectFish {
  position: absolute;
  width: 285px;
  top: 150px;
  right: -150px;
  z-index: 5;
  display: none;

  @media screen and (min-width: 1200px) {
    display: block;
  }

  @media screen and (min-width: 1400px) {
    right: -100px;
  }
}

.flyingObjectLeft {
  position: absolute;
  width: 295px;
  top: 148px;
  left: -110px;
  z-index: 5;
  display: none;

  @media screen and (min-width: 1200px) {
    display: block;
  }
}

.flyingObjectChicken {
  position: absolute;
  width: 290px;
  top: 148px;
  left: -120px;
  z-index: 5;
  display: none;

  @media screen and (min-width: 1200px) {
    display: block;
  }
}

.flyingObjectHantle {
  position: absolute;
  width: 300px;
  top: 148px;
  left: -125px;
  z-index: 5;
  display: none;

  @media screen and (min-width: 1200px) {
    display: block;
  }
}

.flyingObjectMuffin {
  position: absolute;
  width: 295px;
  top: 300px;
  left: -200px;
  z-index: 5;
  display: none;

  @media screen and (min-width: 1200px) {
    display: block;
  }

  @media screen and (min-width: 1400px) {
    left: -160px;
  }
}

.flyingObjectPizza {
  position: absolute;
  width: 375px;
  top: 150px;
  right: -150px;
  z-index: 5;
  display: none;

  @media screen and (min-width: 1200px) {
    display: block;
  }

  @media screen and (min-width: 1400px) {
    right: -200px;
  }
}
